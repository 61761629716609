import axios from '@/common/js/request'
export function sysCityTree(params){ // 城市树
    const sysCityTree = axios({
        url: '/sysCity/tree',
        method: 'get',
        params
    })
    return sysCityTree
}

export function selectAddrPageList(params){ // 分页查询行政区域列表
    const selectAddrPageList = axios({
        url: '/checkAddr/selectAddr',
        method: 'get',
        params
    })
    return selectAddrPageList
}

export function batchDeleteAddr(params){ // 批量删除行政区域
    const batchDeleteAddr = axios({
        url: '/checkAddr/batchDeleteAddr',
        method: 'get',
        params
    })
    return batchDeleteAddr
}

export function checkZyAddrSave(data){ // 新增行政区域管理
    const checkZyAddrSave = axios({
        url: '/checkAddr/save',
        method: 'post',
        data
    })
    return checkZyAddrSave
}

export function checkZyAddrUpdate(data){ // 修改行政区域管理
    const checkZyAddrUpdate = axios({
        url: '/checkAddr/update',
        method: 'post',
        data
    })
    return checkZyAddrUpdate
}